import React from 'react'
import { graphql } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import SEO from '../components/SEO'
import Header from '../components/Header'
import styled from '@emotion/styled'
import Moment from 'react-moment';
import Footer from '../components/Footer'

const Post = styled.div`
  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: normal;
    margin-top: 3rem;
    margin-bottom: .5rem;
    font-weight: bold;
  }
  a {
    color: #494949;
    text-transform: uppercase;
    text-decoration: underline;
    &:hover {
      color: #898989;
      text-decoration: none;
    }
  }
`;

const Press = ({ 
  data: { 
    posts, banner, mobilebanner
  }, 
  pageContext: { 
    locale, slug 
  }, 
  location 
}) => {
  
  const banners = {
    desktop: banner.edges[0].node.original.src,
    mobile: mobilebanner.edges[0].node.original.src,
  }

  return (
    <React.Fragment>
      <SEO 
        pathname={location.pathname} 
        locale={locale} 
        banner={banners.desktop}
        slug={slug}
      />
      <Header 
        slug={slug} 
        locale={locale} 
        location={location}
      />
      <MainWrapper>
        <section className="content about" id="about">
          <div className="container" style={{ marginTop: '5rem' }}>
            {
              posts.nodes.map((item, index) => {
                return (
                  <div className="row" key={`post-${index}`}>
                    <Post>
                      <div dangerouslySetInnerHTML={{ __html: item.data.title.html }}></div>
                      <span>
                        <Moment format="DD/MM/YYYY">
                          {item.data.date}
                        </Moment>
                      </span>
                      <div dangerouslySetInnerHTML={{ __html: item.data.description.html }}></div>
                      {
                        locale === "fr" ? (
                          <a href={item.data.link.url} target="_blank" rel="noreferrer">Lire la suite</a>
                        ) : (
                          <a href={item.data.link.url} target="_blank" rel="noreferrer">Read more</a>
                        ) 
                      }
                    </Post>
                  </div>
                )
              })
            }
          </div>
        </section>
      </MainWrapper>
      <Footer />
    </React.Fragment>
  )
}

export default Press
export const pageQuery = graphql`
  query Press($PrismicLocale: String!) {
    posts: allPrismicPost(filter: {lang: {eq: $PrismicLocale}}, sort: {fields: data___date, order: DESC}) {
      nodes {
        data {
          date
          description {
            html
          }
          image {
            localFile {
              publicURL
            }
          }
          link {
            url
          }
          title {
            html
          }
        }
        alternate_languages {
          uid
          lang
        }
      }
    }
    banner: allImageSharp(filter: {original: {src: {regex: "/david-jarre-header-desktop/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    mobilebanner: allImageSharp(filter: {original: {src: {regex: "/david-jarre-header-mobile/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    logo: allFile(filter: {relativePath: {eq: "david-jarre.com.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    logohover: allFile(filter: {relativePath: {eq: "vasarely.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`